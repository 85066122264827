'use client';

import BottomLinks from './BottomLinks';
import Column from './Column';
import ContactSection from './ContactSection';
import Copyright from './Copyright';
import DownloadSection from './DownloadSection';
import Section from './Section';
import useSections from './useSections';
import {usePathname} from 'next/navigation';

const hideFooterRoutes: RegExp[] = [/^\/[a-z]{2}\/otto$/];

const Footer = () => {
  const sections = useSections();
  const pathname = usePathname();

  const shouldHideFooter = hideFooterRoutes.some((regex) =>
    regex.test(pathname),
  );

  if (shouldHideFooter) return null;

  return (
    <footer className="bg-greyscale-body mt-auto">
      <div className="container py-8 px-4 lg:px-6 xl:px-8 mx-auto lg:space-y-6 lg:divide-y divide-greyscale-label">
        <div className="flex flex-col lg:grid lg:grid-cols-4">
          <Column>
            <ContactSection />
            <DownloadSection />
          </Column>

          <Column>
            <Section data={sections.discoverWinelivery} />
            <Section data={sections.buyProducts} />
          </Column>

          <Column>
            <Section data={sections.corporateServices} />
            <Section data={sections.loyaltyProgram} />
          </Column>

          <Column>
            <Section data={sections.joinTheNetwork} />
            <Section data={sections.workWithUs} />
            <Section data={sections.communityPartners} />
          </Column>
        </div>

        <div className="lg:pt-5 space-y-4">
          <BottomLinks />
          <Copyright />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
