'use client';

import moment from 'moment';
import { useLocale } from 'next-intl';
import Script from 'next/script';
import React, { useEffect } from 'react';
import * as gtm from '../lib/GTM';
import { init as initLeanplum } from '../lib/leanplum';
import useSession from '../stores/session/useSession';
import 'moment/locale/it';
import {usePathname} from 'next/navigation';


export const hideZendeskRoutes: RegExp[] = [
  /^\/[a-z]{2}\/otto$/,
];

export default function InitProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user } = useSession();
  const locale = useLocale();
  const pathname = usePathname();

  const shouldHideZendesk = hideZendeskRoutes.some((regex) => regex.test(pathname));

  useEffect(() => {
    moment.locale(locale);

    if (user) {
      gtm.addEmail(user.email);
    }
  }, []);

  return (
    <>
      {children}
      <Script
        src="https://cdn.jsdelivr.net/npm/leanplum-sdk@1.8.2/dist/leanplum.min.js"
        onLoad={() => {
          if (user) {
            initLeanplum(user);
          }
        }}
      />
      {!shouldHideZendesk && (
        <Script
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_KEY}`}
          onLoad={() => {
            window.zE('messenger:set', 'zIndex', 100);
          }}
        />
      )}
    </>
  );
}
